import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import SEO from "~/components/seo";
import ProductGrid from "~/components/ProductGrid";
import ProductsIntro from "../components/ProductsIntro/ProductsIntro.jsx";
import Header from "../components/Header/Header.jsx";
import HeaderLinks from "../components/Header/HeaderLinks.jsx";

const Bgstylepp = styled.div`
  background: linear-gradient(
    #fff 0%,
    #e6e6e6 23.64%,
    #dedede 50%,
    #e6e6e6 70.92%,
    #fff 100%
  );
`;

const Bgcontainer2 = styled.div`
  color: #040707;
  width: 100%;
  z-index: 12;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15rem;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const IndexPage = () => (
  <>
    <SEO
      title="Cain PPE Store"
      keywords={[`Hand Sanitizer`, `PPE`, `Hygiene`, `Cain PPE`, `Cain`]}
    />
    <Header
      rightLinks={<HeaderLinks />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 100,
        color: "white",
      }}
    />
    <ProductsIntro />
    <Bgstylepp>
      <Bgcontainer2>
        <ProductGrid />
      </Bgcontainer2>
    </Bgstylepp>
  </>
);

export default IndexPage;
