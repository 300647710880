import React from "react";

import { Link } from "gatsby";

import classNames from "classnames";

// material ui:
import withStyles from "@material-ui/core/styles/withStyles";

// main components:

import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Badge from "../Badge/Badge.jsx";

// headerlinks:

import componentsStyle from "../../assets/jss/material-components/views/components.jsx";

import bottles from "../../assets/img/cainallbottles.png";

class ProductsIntro extends React.Component {
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div className={classes.bgcontainerpp}>
        <div className={classes.containerpp}>
          <GridContainer>
            <GridItem xs={8} sm={7} md={6}>
              <h6 className={classes.subheadingpp}>OUR PRODUCTS</h6>
              <h1 className={classes.titlecain}>Introducing CAIN</h1>
              <h5 className={classes.csbrand}>A Culross Solutions Brand</h5>
              <br />
              <p className={classes.testerdesc}>
                Our online store is for wholesale purchase of
                demonstrator/tester units.
              </p>
              <br />
              <Badge color="secondary">COVID-19 APPROVED FORMULA</Badge>
              <Badge color="secondary">High quality products</Badge>
              <Badge color="secondary">2-5 day UK delivery</Badge>
              <Badge color="secondary">Made in the UK</Badge>
            </GridItem>
            <GridItem xs={4} sm={5} md={6}>
              <div className={classes.imagewrappp}>
                <img
                  alt="..."
                  src={bottles}
                  className={classes.bottleimagepp}
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(componentsStyle)(ProductsIntro);
